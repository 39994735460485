import React, { Component } from 'react';
import '../../node_modules/leaflet/dist/leaflet.css';
import {
  Map, TileLayer, Marker, Popup, Tooltip,
} from 'react-leaflet'; // menggunakan library leaflet
import L from 'leaflet';
import { connect } from 'react-redux';
import { setIndexDevice } from '../redux/actions/actions';


const ICON_MAP = (icon) => require(`../assets/picture/icon/map-${icon}.svg`);

// Setting Leaflet
delete L.Icon.Default.prototype._getIconUrl;

// check screen for drag map
const checkScreen = window.screen.width > '991';

// icon leaflet
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Icon di Map
const markerInactive = () => (
  new L.Icon({
    iconUrl: require('../assets/picture/icon/markerIcon-inactive.svg'),
    iconSize: [38, 95], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    tooltipAnchor: [20, -46], // point from which the popup should open relative to the iconAnchor
  })
);

const markerActive = () => (
  new L.Icon({
    iconUrl: require('../assets/picture/icon/markerIcon-active.svg'),
    iconSize: [38, 95], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    tooltipAnchor: [20, -46], // point from which the popup should open relative to the iconAnchor

  })
);


class Maps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: -1.048873,
      long: 119.4777,
      zoom: 11,
    };
  }


  openPopup(marker) {
    if (marker && marker.leafletElement) {
      window.setTimeout(() => {
        marker.leafletElement.openPopup();
      });
    }
  }

  clickMarker =(e) => {
    switch (e.latlng.lat) {
      case -0.873428:
        this.props.setIndexDevice(0);
        break;

      case -0.898525:
        this.props.setIndexDevice(1);
        break;

      case -1.048873:
        this.props.setIndexDevice(2);
        break;

      case -1.13992524147034:
        this.props.setIndexDevice(3);
        break;

      case -1.17416894435883:
        this.props.setIndexDevice(4);
        break;
      default:
    }
  }

  setZoomOut=() => {
  // restart default value, the value must be changed a little bit to enable restart
    this.setState({
      lat: -1.048873 + Math.random() * 0.000001,
      long: 119.4777,
      zoom: 11,
    });
  }

  render() {
    const { indexDevice, dataDevice } = this.props;
    const { lat, long, zoom } = this.state;
    if (dataDevice !== []) {
      return (
        <div className="map-position">
          <button
            className="btn zoomOut bg-white"
            type="button"
            title="Zoom Out"
            onClick={this.setZoomOut}
          >
            <h5 className="mt-auto mb-auto">&#8634;</h5>
          </button>
          <div className="map">
            <div key="map" className="map-elemen">
              <Map
                id="map"
                className="media"
                center={[lat, long]}
                zoom={zoom}
                zoomControl
                scrollWheelZoom={false}
                dragging={checkScreen}
              >
                <TileLayer
                  attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                  dataDevice
                    ? dataDevice.map((item, i) => (
                      <Marker
                        key={i}
                        onClick={(e = item.name) => this.clickMarker(e)}
                        ref={this.openPopup}
                        position={[item.latitude, item.longitude]}
                        icon={i === indexDevice ? markerActive() : markerInactive()}
                      >
                        <Tooltip permanent>
                          <div className="row ml-2" style={{ width: '190px' }}>
                            <img height="30" alt="Icon Cuaca" src={ICON_MAP(item.latestData.data[6].status)} />
                            <div className="ml-2">
                              <h6>{item.name}</h6>
                              <h6 className="text-muted text-capitalize" style={{ marginTop: '-5px' }}>{item.latestData.data[6].status==null ? "Normal" : item.latestData.data[6].status}</h6>
                            </div>
                          </div>
                        </Tooltip>
                      </Marker>
                    )) : null
          }
              </Map>
            </div>
          </div>
        </div>

      );
    }
    return (<div className="loader" />);
  }
}


const mapStateToProps = (state) => ({
  dataDevice: state.dataDevice,
  indexDevice: state.indexDevice,
});

const mapDispatchToProps = {
  setIndexDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Maps);
